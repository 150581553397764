import styled from 'styled-components'
import { bgColors } from 'style/config'

export const Div = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${bgColors['starlight']};
    z-index: 99;
    position: absolute;
    box-shadow: 1px 2px #888888;
    border: 2px solid #CCC;
    align-items: center;
    justify-content: center;
    transform: scaleX(${({ flip }) => flip === 'true' ? -1 : 1 });
    user-select: none;
    cursor: pointer;
    display: flex;
    @media (min-width: 1025px) {
        display: none;
    }
`