import styled from 'styled-components'

export const Div = styled.div`
    position: absolute;
    z-index: 1;
    background-color: #EEE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    > h2 {
        font-size: 48px;
        margin-bottom: 24px;
    }
    > p {
        text-align: center;
        font-weight: bold;
    }
`