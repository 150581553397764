export const bgColors = {
    grape: '#ffdfff',
    fog: '#dadee9',
    seafoam: '#aaffcf',
    starlight: '#dfefff',
    ice: '#bbe4ea',
    'ghost green': '#efefcf',
    chestnut: '#ddadaf',
    sand: '#ffefcf',
    banana: '#ffefbf',
    curd: '#fff9d0'
}

export const gutters = {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xlg: '36px',
    xl: '48px',
    xxl: '72px',
    xxxl: '120px'
}

export const fontSize = {
    xs: '11px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xl: '48px'
}