import styled from 'styled-components'

export const Div = styled.div`
    height: 100%;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 36px;
    > div {
        &:first-of-type {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 48px;
            > div {
                &:first-of-type {
                    margin-right: 48px;
                    > img {
                        width: 100%;
                        border-radius: 10px;
                        border: 4px solid #333;
                    }
                }
                &:last-of-type {
                    padding-top: 8px;
                    > ul {
                        > li {
                            font-size: 16px;
                            margin-bottom: 12px;
                            > span {
                                &:first-of-type {
                                    font-weight: bold;
                                }
                                &:last-of-type {
                                    font-size: 14px;
                                }
                            }
                        }
                    } 
                }
            }
        }
        &:last-of-type {
            width: 100%;
            margin: 0 36px;
            > p {
                margin-bottom: 18px;
                &:first-of-type {
                    font-weight: bold;
                    margin-bottom: 18px;
                    > span {
                        margin-left: 36px;
                        font-weight: normal;
                        padding: 2px 6px;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
`