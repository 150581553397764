import * as Styled from './Menu.style'
import { ReactComponent as Farm } from'svg/farm.svg'
import { ReactComponent as Horse } from 'svg/horse.svg'
import { ReactComponent as Horse21 } from 'svg/horse/21.svg'
import { ReactComponent as News } from 'svg/news.svg'

const Menu = ({ avatar, account, handleClick }) =>
  <Styled.Div>
    <h1>~ {'The Paddock'} ~</h1>
    {avatar >= 0 && (
      <>
        <Horse onClick={() => handleClick(0)} />
        <Farm onClick={() => handleClick(1)} />
        <Horse21 onClick={() => handleClick(2)} />
        {/* <Horse onClick={() => handleClick(3)} /> */}
        {/* <Horse onClick={() => handleClick(4)} /> */}
        <News onClick={() => handleClick(5)} />
      </>
    )}
  </Styled.Div>

export default Menu
