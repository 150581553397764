import styled from 'styled-components'

export const Main = styled.main`
    width: 100%;
`

export const Div = styled.div`
    position: fixed;
    min-width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    height: 100vh;
    
    > div {
        background-color: #FFF;
        box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 12px 0 15px -4px rgba(31, 73, 125, 0.2), -12px 0 15px -4px rgba(31, 73, 125, 0.2);
        padding: 0;
        border-radius: 20px;
        position: relative;
        margin-top: 1vh;
        height: 96vh;
        width: 90%;
        padding: 0 5%;
        max-width: 900px;
        overflow: auto;
        > p {
            position: absolute;
            z-index: 101;
            top: 20px;
            right: 20px;
            cursor: pointer;
            border: 1px solid #000;
            border-radius: 2px;
            padding: 4px 6px;
            &:hover {
                opacity: 0.5;
            }
         }
    }
   
`

export const Section = styled.section`
    padding-top 80px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 100px;
    > h2 {
        font-weight: bold;
        margin-bottom: 12px;
        text-align: center;
        font-size: 12px;
        @media (min-width: 400px) {
            margin-bottom: 18px;
            font-size: 16px;
        }
        @media (min-width: 600px) {
            margin-bottom: 24px;
            font-size: 24px;
        }
    }
    > h3 {
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;
        font-size: 12px;
        @media (min-width: 400px) {
            font-size: 16px;
        }
        @media (min-width: 600px) {
            font-size: 24px;
        }
        > a { color: #000; }
    }
    > p {
        font-size: 18px;
        margin-bottom: 24px;
    }
    > input {
        margin-bottom: 18px;
        padding: 8px 12px;
    }
    > ul {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        > li {
            width: 100%;
            @media (min-width: 600px) {
                width: 50%
            }
            @media (min-width: 1000px) {
                width: 20%;
            }
            margin-bottom: 24px;
        }
     }
`