import styled from 'styled-components'

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    > h2, p {
        margin-bottom: 24px;
    }
    > button {
        background: #FFF;
        padding: 8px 12px;
        margin-bottom: 12px;
        min-width: 240px;
    }
`