let startRace = false
let racing = false
let startTime = null
let raceOver = false
let i = 0
let raceHorse1Finished = false
let raceHorse2Finished = false

const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
const horseFinishedRace = []

const MoveBox = (entities, { input }) => {
  const { payload } = input.find(x => x.name === "onKeyDown") || {};
  if (i === 0) {
    const { message0, horse1: { socket } } = entities
    message0.opacity = 1
    socket.on('things', things => {
      const { turtlesOfSpeed, ducksOfDoom, ghostsOfDeath } = things
      turtlesOfSpeed.map((thing, i) => {
        entities[`turtlesOfSpeed${i}`].transform = entities[`turtlesOfSpeed${i}`].left < thing.left
        entities[`turtlesOfSpeed${i}`].left = thing.left
      })
      ducksOfDoom.map((thing, i) => {
        entities[`ducksOfDoom${i}`].transform = entities[`ducksOfDoom${i}`].left < thing.left
        entities[`ducksOfDoom${i}`].left = thing.left
      })
      ghostsOfDeath.map((thing, i) => {
        entities[`ghostsOfDeath${i}`].transform = entities[`ghostsOfDeath${i}`].left < thing.left
        entities[`ghostsOfDeath${i}`].left = thing.left
      })
    })
    socket.on('move', (_players) => {
      _players.forEach((player) => {
        if (entities[`player${player.tokenId}`] && player.level === 0 && player.tokenId > -1 ) {
          entities[`player${player.tokenId}`].left = player.left
          entities[`player${player.tokenId}`].top = player.top
          entities[`player${player.tokenId}`].transform = player.transform
          entities[`player${player.tokenId}`].newbRaceOver = player.newbRaceOver
        }
      })
    })
  }

  i++

  if (i % 4 === 0) {
    const { raceHorse1, raceHorse2, horse1, raceFinishLine, message5, debug, safeArea, podium } = entities
    const rando = randomIntFromInterval(2,9)
    if (racing) {
      if (horse1.left + rando >= 1930) {
        const endTime = Date.now(); // Record the end time
        raceOver = true
        const elapsedTime = endTime - startTime
        horse1.socket.emit('wonRace', { 
          race: 'Newb Island Race',
          tokenId: horse1.horse.tokenId,
          winner: horse1.owner,
          time: elapsedTime,
          riders: [
            { tokenId: raceHorse1.horse.tokenId, owner: undefined, time: undefined }, // TODO - would be great to have all times collected here
            { tokenId: raceHorse2.horse.tokenId, owner: undefined, time: undefined }
          ]
        })
        racing = false
        raceFinishLine.backgroundColor = '#EEE'
        message5.opacity = 1
        setTimeout(() => { message5.opacity = 0 }, 10000)
        if (podium.result.length === 0) {
          podium.opacity = 1
        }
        podium.result.push(horse1)
      } else {
        const increment = horse1.left + rando
        horse1.handleMoveX(0 - rando)
        horse1.left = increment
        debug.x = increment
        debug.translateX = debug.translateX + rando
        safeArea.left = safeArea.left + rando
        if (!raceHorse1Finished) {
          if (raceHorse1.left + rando <= 1930) {
            raceHorse1.left = raceHorse1.left + randomIntFromInterval(2,8)
          } else {
            if (podium.result.length === 0) {
              podium.opacity = 1
            }
            podium.result.push(raceHorse1)
            raceHorse1Finished = true
          }
        }
        if (!raceHorse1Finished) {
          if (raceHorse2.left + rando <= 1930) {
            raceHorse2.left = raceHorse2.left + randomIntFromInterval(2,8)
          } else {
            if (podium.result.length === 0) {
              podium.opacity = 1
            }
            podium.result.push(raceHorse1)
            raceHorse2Finished = true
          }
        }
      }
    }
    else {
      if (raceOver) {
        if (!raceHorse1Finished) {
          if (raceHorse1.left + rando <= 1930) {
            raceHorse1.left = raceHorse1.left + randomIntFromInterval(2,8)
          } else {
            if (podium.result.length === 0) {
              podium.opacity = 1
            }
            raceHorse1Finished = true
            podium.result.push(raceHorse1)
          }
        }
        if (!raceHorse2Finished) {
          if (raceHorse2.left + rando <= 1930) {
            raceHorse2.left = raceHorse2.left + randomIntFromInterval(2,8)
          } else {
            raceHorse2Finished = true
            if (podium.result.length === 0) {
              podium.opacity = 1
            }
            podium.result.push(raceHorse2)
          }
        }
      }
    }
  }
  

  if (payload && !startRace && !racing) {
    const { horse1, safeArea, paddock: { paths }, debug, raceStart, raceStartLine, raceStartCountdown } = entities
    const sA = {
      left: safeArea.left,
      right: safeArea.left + safeArea.width,
      top: safeArea.top,
      bottom: safeArea.top + safeArea.height
    }
    let increment = payload.keyCode === 40 || payload.keyCode === 39 ? horse1.speed : 0 - horse1.speed
    if (payload.keyCode === 40 || payload.keyCode === 38) {
      horse1.tabIndex = 0
      const a = { left: horse1.left, right: horse1.left + 120, top: horse1.top + increment, bottom: horse1.top + 120 + increment }
      if (a.top > 40) {
        horse1.top = isOnPath(a, paths) || raceOver || horse1.newbRaceOver ? horse1.top + increment : horse1.top
        horse1.socket.emit('userMove', {
          left: horse1.left,
          top: horse1.top,
          account: horse1.owner,
          transform: horse1.transform,
          tokenId: horse1.horse.tokenId,
          newbRaceOver: (raceOver || horse1.newbRaceOver),
          hay: horse1.hay
        })
        debug.x = horse1.left
        debug.y = horse1.top
        if (!inSafeZoneY(sA, a)) {
          horse1.handleMoveY(increment > 0 ? 0 - horse1.speed : horse1.speed)
          debug.translateY = debug.translateY + increment
          safeArea.top = sA.top + increment
        }
      }
    }
    if (payload.keyCode === 39 || payload.keyCode === 37) {
      horse1.transform = payload.keyCode === 37
      const a = { left: horse1.left + increment, right: horse1.left + 120 + increment, top: horse1.top, bottom: horse1.top + 120 }
      if (a.left > 100) {
        horse1.left = isOnPath(a, paths) || raceOver || horse1.newbRaceOver ? horse1.left + increment : horse1.left
        horse1.socket.emit('userMove', {
          left: horse1.left,
          top: horse1.top,
          account: horse1.owner,
          transform: horse1.transform,
          tokenId: horse1.horse.tokenId,
          hay: horse1.hay,
          newbRaceOver: (raceOver || horse1.newbRaceOver)
        })
        debug.x = horse1.left
        debug.y = horse1.top
        if (!inSafeZoneX(sA, a)) {
          horse1.handleMoveX(increment > 0 ? 0 - horse1.speed : horse1.speed)
          debug.translateX = debug.translateX + increment
          safeArea.left = sA.left + increment
        }
        if (doOverlap(a,paths[1])) {
          entities.message1.opacity = 1
        }
        if (doOverlap(a,paths[3])) {
          entities.message2.opacity = 1
        }
        if (doOverlap(a,paths[7])) {
          entities.message3.opacity = 1
        }
        if (doOverlap(a,paths[10])) {
          entities.message4.opacity = 1
        }
        if (!raceOver) {
          if (doOverlap(a,paths[11])) {
            if (a.left >= raceStart.left - 10) {
              startRace = true
              raceStartCountdown.display = 'flex'
              raceStartCountdown.text = '3'
              setTimeout(() => {
                startRace = false
                startTime = Date.now()
                racing = true
                raceStartCountdown.text = 'GO'
                raceStartLine.backgroundColor = '#EEE'
                setTimeout(() => {
                  raceStartCountdown.display = 'none'
                }, 1000)
              }, 3000)
              setTimeout(() => {
                raceStartCountdown.text = '1'
              }, 2000)
              setTimeout(() => {
                raceStartCountdown.text = '2'
              }, 1000)
            }
          }
        }
      }
    }
  }
  return entities;
};

const inSafeZoneX = (a, { left, right }) => (a.right >= right && a.left <= left)
const inSafeZoneY = (a, { top, bottom }) => (a.bottom >= bottom && a.top <= top)

const isOnPath = (a, paths) => !(paths.find(path => doOverlap(a, path)) === undefined)

const doOverlap = (a, { safeZone: { left, right, top, bottom }}) =>
  !(a.left >= right || a.top >= bottom || a.right <= left || a.bottom <= top)

export default MoveBox
