import { useEffect, useState } from 'react'
import farm from 'svg/farm.svg'
import * as Styled from './Messages.style'

const formatDate = (date) => {
  const d = new Date(date);
  const day = d.toLocaleDateString('en-GB', { day: 'numeric' });
  const month = d.toLocaleDateString('en-GB', { month: 'short' });
  const year = d.toLocaleDateString('en-GB', { year: 'numeric' });
  const time = d.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  return `${time} - ${day} ${month} ${year}`;
};

const Messages = ({ assets, accounts, socket }) => {
  const [messages, setMessages] = useState([])

  useEffect(() => {
    socket.emit('getMessages')
    socket.on('messages', data => {
      setMessages([...data].reverse())
    })
  }, [])

  return (
    <Styled.Div>
      {messages.map(({ account, createdAt, message }, i) => {
        let avatar = accounts.find(_account => _account.account === account) && accounts.find(_account => _account.account === account).tokenId >= 0
          ? <div style={{ 
              backgroundImage: `url(${assets.find(({ tokenId }) => tokenId === accounts.find(_account => _account.account === account).tokenId).svg})`, 
              backgroundSize: '250% auto',
              backgroundPosition: '75% 25%'
            }} />
          : <div style={{ backgroundImage: `url(${farm})`}} />
        return (
          <div key={i}>
            <div>
              {avatar}
            </div>
            <div>
              <div><span>{account}</span></div>
              <div>{message}</div>
              <div>{formatDate(createdAt)}</div>
            </div>
          </div>
        )
      })}
    </Styled.Div>
  )
}

export default Messages
