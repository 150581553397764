import React, { PureComponent, useState, useEffect, useRef } from "react";
import { GameEngine } from "react-game-engine";
import MoveBox from "./systems"
import Horse from 'components/Horse'
import Thing from 'components/Thing'
import Pond from 'components/Pond'
import Clock from 'components/Clock'
import LeaderBoard from 'components/LeaderBoard/LeaderBoard'
import Cemetry from 'components/Cemetry'
import Traits from 'components/Traits'
import bonsai from 'svg/horse/Bonsai.svg'
import flower from 'svg/horse/Flower.svg'
import farouk from 'svg/31db13b10188de1afd6cff09cf65a0ae.svg'
import turtle from 'svg/horse/Turtle.svg'
import duck from 'svg/horse/Duck.svg'
import ghost from 'svg/horse/Ghost.svg'
import fence from 'img/fence.png'
import farm from 'svg/farm.svg'
import forest from 'svg/forest.svg'
import { bgColors } from 'style/config'
import { paths, rivers } from "./set";


export default class Intro extends PureComponent {
  constructor(props) {
    super(props);
    const { account, players, size } = this.props
    const player = players.find(player => player.account === account)
    const translateX = player && size.width / 2 - player.left < 0 ? size.width / 2 - player.left : 0;
    const translateY = player && size.height / 2 - player.top < 0 ? size.height / 2 - player.top : 0;
    this.state = { translateX, translateY }
  }

  componentDidUpdate(prevProps) {
    const { account, players, size } = this.props;

    // Check if the relevant props have changed
    if (size !== prevProps.size) {
      const player = players.find((player) => player.account === account);
      const translateX =
        player && size.width / 2 - player.left < 0
          ? size.width / 2 - player.left
          : 0;
      const translateY =
        player && size.height / 2 - player.top < 0
          ? size.height / 2 - player.top
          : 0;

      // Update the state with the new values
      this.setState({ translateX, translateY });
    }
  }

  render() {
    const { avatarId, attributes, size, account, horses, total, players: _players, paddockWidth, paddockHeight, murdered, things: { bonsaiOfLife, flowers, ghostsOfDeath, turtlesOfSpeed, ducksOfDoom  }, level, socket, gameRef, handleHorseClick } = this.props
    const player = _players.find(player => player.account === account)
    const players =  _players.filter(player => !(player.account === account))
    const horse = horses.find(h => h.tokenId === avatarId)
    const horse1 = {
      left: player.left,
      top: player.top,
      speed: 10,
      size: 120,
      horse,
      hay: player.hay,
      handleHorseClick,
      transform: false,
      owner: account,
      newbRaceOver: player.newbRaceOver,
      handleMoveX: move => {
        this.state.translateX + move &&
          this.setState(prevState => ({ translateX: prevState.translateX + move }))
      },
      handleMoveY: move => {
        this.state.translateY + move < 0 &&
          this.setState(prevState => ({ translateY: prevState.translateY + move }))
      },
      socket,
      renderer: <Horse />,
    }

    const messages = [
      {
        left: 60, top: 280, width: '300px', opacity: 0,
        message: `<b>Welcome to The Meadowverse</b><br />🐎 🐎 🐎 🐎 🐎<br />Population: <b>${players.filter(player => player.tokenId >= 0).length}</b><br /><br />Use your arrow keys:<br />&#x2B06; &#x27A1; &#x2B07; &#x2B05;<br />to move your horse`,
      },
      {
        left: 70, top: 470, width: '280px', opacity: 0,
        message: 'please stay on the path<br />while we show you around',
      },
      {
        left: 1040, top: 260, width: '800px', opacity: 0,
        message: 'So what can you do in the Paddock? The choice is yours: you could chat to other Chained Horse owners at <b>Engagement Farm</b>, enjoy some solitude at <b>RainbowPuke Falls</b> or maybe participate in some daily challenges to earn some useful <b>$HAY</b>',
      },
      {
        left: 1040, top: 960, width: '360px', opacity: 0,
        message: 'Every ChainedHorse in the Paddock is entitled to a <b>"Shitty Stable"</b>. Collecting <b>$HAY</b> allows you to upgrade your stable and make it less shitty. One way to earn <b>$HAY</b> is to hang out here at <b>Engagement Farm</b> where you can acquire <b>$HAY</b> just for being here.',
      },
      {
        left: 340, top: 1120, width: '510px', opacity: 0,
        message: 'Some horses are born to compete and enjoy putting their jockeying skills to the test at the various Race Tracks that can be found throughout the Paddock. <br /><br />Start a <b>Race</b> by going to your start position in the bottom stall',
      },
      {
        left: 2200, top: 1420, width: '360px', opacity: 0,
        message: '<b>Well Done!</b><br />You took part in a race.<br /><br />You are now free to roam around the Paddock. From now on the path you take is up to you.',
      }
    ]

    const entities = {}

    entities.horse1 = horse1

    players.forEach((player, i) => {
      if (player.level === level && player.tokenId > -1 && player.newbRaceOver) {
        const horse = horses.find(h => h.tokenId === player.tokenId);
        entities[`player${player.tokenId}`] = {
          left: player.left,
          top: player.top,
          transform: player.transform,
          speed: 10,
          size: 120,
          horse,
          handleHorseClick,
          newbRaceOver: player.newbRaceOver,
          renderer: <Horse />,
        };
      }
    });


    entities.raceTrackRecords = {
      left: 1200, top: 1335, width: 380, height: 120, race: "Newb Island Race", socket,
      renderer: <LeaderBoard /> 
    }

    entities.forest = {
      left: 3500, top: 1000, width: 500, height: 500, zIndex: 2, position: 'absolute', opacity: 0.8,
      backgroundImage: `url(${forest})`, backgroundSize: 'contain',
      renderer: <Forest />
    }
    entities.forest2 = {
      left: 3910, top: 1050, width: 400, height: 400, zIndex: 2, position: 'absolute', opacity: 0.8,
      backgroundImage: `url(${forest})`, backgroundSize: 'contain',
      renderer: <Forest />
    }

    entities.forestTitle = {
      left: 3410, top: 980, width: 400, height: 400, zIndex: 2, position: 'absolute', opacity: 0.6,
      renderer: ({ left, top, width, height, zIndex, position, opacity }) => 
        <h2 style={{ left, top, width, height, zIndex, position, opacity }}>{'Probably Wood'}</h2>
    }

    entities.FarokhAokitheHappyBrownBear = {
      left: 3780, top: 1100, width: 400, height: 400, zIndex: 1, position: 'absolute',
      backgroundImage: `url(${farouk})`, backgroundSize: 'contain',
      renderer: ({ left, top, width, height, zIndex, position, backgroundImage, backgroundSize }) =>
        <div style={{ left, top, width, height, zIndex, position, backgroundImage, backgroundSize }}>
          <div style={{ marginTop: 360, marginLeft: 200, position, width: 400 }}>
            {'If you look closely sometimes you can get a glimpse of Kitty International\'s Two Bit Bear'}
          </div>
        </div>
    }

    entities.cemetry = {
      left: 3140, top: 2600, width: 800, height: 400, zIndex: 1, position: 'absolute', murdered,
      renderer: <Cemetry />
    }

    paths.map((path, i) => {
      entities[`path${i}`] = path
      entities[`path${i}`].renderer = <Path />
      entities[`path${i}`].safeZone = {
        left: path.left + 90,
        right: path.left + path.width - 90,
        top: path.top + 80,
        bottom: path.top + path.height - 90
      }
    })

    rivers.map((path, i) => {
      entities[`rivers${i}`] = path
      entities[`rivers${i}`].renderer = <River />
      entities[`rivers${i}`].safeZone = {
        left: path.left + 90,
        right: path.left + path.width - 90,
        top: path.top + 80,
        bottom: path.top + path.height - 90
      }
    })

    messages.map((message, i) => {
      entities[`message${i}`] = message
      entities[`message${i}`].renderer = <Message />
    })

    entities.scareCity = {
      left: 2300, top: 50, attributes, total, player: horse1, socket,
      renderer: <Traits />
    }

    entities.clock = {
      left: 2840, top: 900, width: 200, height: 140, socket,
      renderer: <Clock />
    };

    entities.colors1 = {
      left: 2770, top: 830, width: 600, bgColors,
      renderer: ({ left, top, width }) => <div style={{ left, top, width, position: 'absolute', zIndex: 1 }}>
        {Object.keys(bgColors).map((key, i) => i <= 3 && <div key={key} style={{ width: 70, height: 70, backgroundColor: key === 'seafoam' ? '#8b4513' : bgColors[key]}} />)}
      </div>
    };
    entities.colors2 = {
      left: 2840, top: 1040, width: 600, bgColors,
      renderer: ({ left, top, width }) => <div style={{ left, top, width, position: 'absolute', zIndex: 1, display: 'flex' }}>
        {Object.keys(bgColors).map((key, i) => i >= 4 && i <= 5 && <div {...{ key }} style={{ width: 100, height: 70, backgroundColor: key === 'seafoam' ? '#8b4513' : bgColors[key]}} />)}
      </div>
    };
    entities.colors3 = {
      left: 3040, top: 830, width: 600, bgColors,
      renderer: ({ left, top, width }) => <div style={{ left, top, width, position: 'absolute', zIndex: 1 }}>
        {Object.keys(bgColors).map((key, i) => i >= 6 && i <= 9  && <div key={key} style={{ width: 70, height: 70, backgroundColor: key === 'seafoam' ? '#8b4513' : bgColors[key]}} />)}
      </div>
    };
    entities.colors4 = {
      left: 2840, top: 830, width: 600, bgColors,
      renderer: ({ left, top, width }) => <div style={{ left, top, width, position: 'absolute', zIndex: 1, display: 'flex' }}>
        {Object.keys(bgColors).map((key, i) => i >= 4 && i <= 5 && <div {...{ key }} style={{ width: 100, height: 70, backgroundColor: key === 'seafoam' ? '#8b4513' : bgColors[key]}} />)}
      </div>
    };

    const horse2 = horses.find(h => h.tokenId === 69)
    const horse3 = horses.find(h => h.tokenId === 91)

    entities.raceHorse1 = {
      left: 580,
      top: 1490,
      speed: 10,
      size: 120,
      horse: horse2,
      handleHorseClick,
      transform: false,
      renderer: <Horse />
    }

    entities.raceHorse2 = {
      left: 580,
      top: 1620,
      speed: 10,
      size: 120,
      horse: horse3,
      handleHorseClick,
      transform: false,
      renderer: <Horse />
    }

    entities.rainbowPukeFalls = {
      left: 40,
      top: 2500,
      renderer: <Pond />
    }

    entities.podium = {
      left: 1880, top: 1360, width: 260, height: 80, backgroundColor: '#CCC', opacity: 0,
      result: [],
      renderer: <Podium />
    }

    entities.raceStall1 = {
      left: 580, top: 1510, width: 120, height: 80, backgroundColor: '#CCC',
      renderer: <StartersOrders />
    }

    entities.raceStall2 = {
      left: 580, top: 1640, width: 120, height: 80, backgroundColor: '#CCC',
      renderer: <StartersOrders />
    }

    entities.raceStart = {
      left: 580, top: 1780, width: 120, height: 80, backgroundColor: '#CCC',
      renderer: <StartersOrders />
    }

    entities.raceStartLine = {
      left: 700, top: 1480, width: 10, height: 420, backgroundColor: '#888',
      renderer: <StartersOrders />
    }

    entities.raceFinishLine = {
      left: 1990, top: 1480, width: 10, height: 420, backgroundColor: '#888',
      renderer: <StartersOrders />
    }

    entities.raceStartCountdown = {
      left: 800, top: 1640, width: 80, height: 80, backgroundColor: '#FFF', display: 'none',
      renderer: <Countdown />
    }

   entities.raceTrack = {
     left: 700, top: 1480, width: 1250, height: 400, backgroundColor: 'transparent',
     renderer: <Path />
   }

    const bonsaiPos = [{ top: 400, left: 580 }, { top: 200, left: 800 }]
      bonsaiOfLife && bonsaiOfLife.map((thing, i) => {
      if (bonsaiPos.length > i) {
        entities[`bonsaiOfLife${i}`] = {
          imgsrc: bonsai, tip: thing._doc.utility+' '+ thing._doc.owner,
          width: 68, ...bonsaiPos[i], transform: false,
          renderer: <Thing />
        }
      }
    })

    ghostsOfDeath && ghostsOfDeath.map((thing, i) => {
        entities[`ghostsOfDeath${i}`] = {
          imgsrc: ghost, tip: thing._doc.utility+' '+ thing._doc.owner,
          width: '60px', left: thing.left, top: thing.top, transform: false,
          renderer: <Thing />
        }
    })

    flowers && flowers.map((thing, i) => {
      entities[`flower${i}`] = {
        imgsrc: flower, tip: thing._doc.utility+' '+ thing._doc.owner,
        width: '50px', left: thing.left, top: thing.top, transform: false,
        renderer: <Thing />
      }
    })

    turtlesOfSpeed && turtlesOfSpeed.map((thing, i) => {
      entities[`turtlesOfSpeed${i}`] = {
        imgsrc: turtle, tip: thing._doc.utility+' '+ thing._doc.owner,
        width: '50px', left: thing.left, top: thing.top, transform: false,
        renderer: <Thing />
      }
    })

    ducksOfDoom && ducksOfDoom.map((thing, i) => {
      entities[`ducksOfDoom${i}`] = {
        imgsrc: duck, tip: thing._doc.utility+' '+ thing._doc.owner,
        width: '50px', left: thing.left, top: thing.top, transform: false,
        renderer: <Thing />
      }
    })

    const { translateX, translateY } = this.state
    
    entities.safeArea = {
      left: size.width / 20 - translateX,
      top: size.height / 20 - translateY,
      width: (size.width / 10) * 9,
      height: (size.height / 10) * 9,
      viewportSize: size,
      renderer: <SafeArea />
    }

    entities.paddock = {
      left: 0, top: 0, width: paddockWidth, height: paddockHeight, paths, renderer: <Paddock />
    }

    entities.farm = {
      left: 1650, top: 860, width: 160, height: 100,
      renderer: <Farm />
    }

    entities.pond = {
      left: 1040, top: 500, width: 500, height: 340,
      renderer: <FarmPond />
    };

    [0,1,2,3,4].map(i => {
      entities[`furrow${i}`] = {
        left: 1600 + (i * 56), top: 510, width: '28px', height: '320px',
        renderer: <Furrow />
      }
    })

    entities.fence1 = {
      left: 700, top: 1449, width: 1300, height: 40,
      renderer: <Fence />
    }

    entities.fence2 = {
      left: 700, top: 1860, width: 1300, height: 40,
      renderer: <Fence />
    }

    entities.debug = {
      x: horse1.left,
      y: horse1.top,
      translateX: 0 - translateX,
      translateY: 0 - translateY,
      renderer: <Debug />
    }

    return (
      <div style={{ width: '100%', height: '100vh', overflow: 'hidden', position: 'relative' }}>
        <div style={{ transform: `translate(${translateX}px,${translateY}px)`}}>
        <GameEngine
          ref={gameRef}
          style={{ width: paddockWidth, height: paddockHeight }}
          systems={[MoveBox]}
          {...{ entities }}
        />
        </div>
      </div>
    )
  }
}

const Forest = ({ left, top, width, height, zIndex, position, backgroundImage, backgroundSize, opacity }) =>
      <div style={{ left, top, width, height, zIndex, position, backgroundImage, backgroundSize, opacity }} />
      

const Debug = ({ x, y, translateX, translateY }) => <div style={{ 
    display: process.env.NODE_ENV === 'development' ? 'block' : 'none',
    position: 'fixed', zIndex: 1000, top: 50, left: 10, transform: `translate(${translateX}px,${translateY}px)` }}>
    {`x: ${x} y: ${y}`}<br />
    {`offsetX: ${translateX} offsetY: ${translateY}`}<br />
  </div>
const SafeArea = ({ left, top, translateX, translateY }) => <div style={{ position: 'absolute', display: 'none', zIndex: 100, left, top, width: '90%', height: '90vh', backgroundColor: 'rgba(0,0,0,0)', opacity: 0, transform: `translate(${translateX}px,${translateY}px)` }} />

const Path = ({ left, top, width, height, backgroundColor, border }) =>
  <div style={{ position: 'absolute', zIndex: 1, left, top, width, height, backgroundColor, border }} />

const River = ({ left, top, width, height, backgroundColor, border }) =>
  <div style={{ position: 'absolute', zIndex: 1, left, top, width, height, backgroundColor, border }} />

const StartersOrders = ({ left, top, width, height, backgroundColor }) =>
  <div style={{ position: 'absolute', zIndex: 1, left, top, width, height, backgroundColor }} />

const Countdown = ({ left, top, width, height, backgroundColor, text, display }) =>
  <div style={{ position: 'absolute', zIndex: 1, left, top, width, height, backgroundColor, display, alignItems: 'center', justifyContent: 'center', fontSize: '28px', fontWeight: 'bold' }}>{text}</div>

const Fence = ({ left, top, width, height }) =>
  <div style={{ position: 'absolute', zIndex: 0, left, top, width, height, backgroundImage: `url('${fence}')`, backgroundSize: 'auto 200%' }} />

const Paddock = ({ x, y, width, height }) =>
  <div style={{ position: 'absolute', zIndex: 0, backgroundColor: '#aaffcf', left: 0, top: 0, width, height }} />

const Message = ({ left, top, width, message, opacity }) => {
  return <div style={{ position: 'absolute', transition: 'opacity .5s', border: '1px solid #CCC', opacity, zIndex: 10, lineHeight: '22px', borderRadius: '5px', textAlign: 'center', padding: '12px', backgroundColor: '#FFF', left, top, width }} dangerouslySetInnerHTML={{ __html: message }} />
}

const Podium = ({ left, top, width, height, opacity, result }) => {
  return (
    <div style={{ position: 'absolute', transition: 'opacity .5s', border: '1px solid #CCC', opacity, zIndex: 10, lineHeight: '22px', borderRadius: '5px', textAlign: 'center', backgroundColor: '#FFF', left, top, width, height }}>
      {result.map((horse, i) => {
        const positions = [{ left: 102, top: 8 }, { left: 42, top: 18 }, { left: 170, top: 28 }]
        return <Horse key={i} horse={horse.horse} left={positions[i].left} top={positions[i].top} size={50} textTop={true} />
      })}
      <div style={{ position: 'absolute', height: '10px', width: 200, backgroundColor: '#333', bottom: 0, left: 30 }} />
      <div style={{ position: 'absolute', height: '10px', width: 133, backgroundColor: '#333', bottom: 10, left: 30 }} />
      <div style={{ position: 'absolute', height: '10px', width: 66, backgroundColor: '#333', bottom: 20, left: 97 }} />
    </div>
  )
}

const Furrow = ({ left, top, width, height }) =>
  <div style={{ position: 'absolute', width, left, top, height, backgroundColor: '#8b4513' }} />

const FarmPond = ({ left, top, width, height }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', zIndex: 1, borderRadius: '5px', left, top, width, height, backgroundColor: '#37d7ff' }}>
    </div>
  )
}

const Farm = ({ left, top, width, height }) =>
  <div id={'farm'} style={{ position: 'absolute', display: 'flex', flexDirection: 'column', zIndex: 1, width, height, left, top, opacity: 0.6 }}>
    <div>
      <img src={farm} alt={''} style={{ width, height }} />
    </div>
    <div>{'Engagement Farm'}</div>
  </div>
