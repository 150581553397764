import { useEffect, useState } from 'react'

const Pond = ({ top, left }) => {
  const [waterfall, setWaterfall] = useState(0)
  useEffect(() => {
  const intervalId = setInterval(() => waterfall === 9
    ? setWaterfall(0)
    : setWaterfall(waterfall + 1)
  , 10)

  return () => clearInterval(intervalId)

}, [waterfall])


  return (
    <div style={{
      display: 'flex',
      position: 'absolute',
      height: '320px',
      width: '300px',
      background: '#37d7ff',
      borderRadius: '10px',
      zIndex: 1,
      top, left
    }}>
      <h2 style={{ position: 'absolute', marginTop: '-20px', opacity: 0.4, fontSize: '10px' }}>{'RainbowPuke Falls'}</h2>
      <svg viewBox='0 0 32 32' style={{ position: 'absolute', marginLeft: '2px' }}>
        <path fill='#86c661' d={`M2 ${waterfall + 2}h1v1h-1zm0 1h1v1h-1z`} />
        <path fill='#04b3e9' d={`M2 ${waterfall + 4}h1v1h-1z`} />
        <path fill='#86c661' d={`M2 ${waterfall + 7}h1v1h-1zm0-1h1v1h-1z`} />
        <path fill='#04b3e9' d={`M2 ${waterfall + 9}h1v1h-1z`} />
        <path fill='#fbee41' d={`M1 ${waterfall}h1v1h-1zm0 2h1v1h-1z`} />
        <path fill='#f58220' d={`M1 ${waterfall + 1}h1v1h-1z`} />
        <path fill='#fbee41' d={`M1 ${waterfall + 4}h1v1h-1zm0 2h1v1h-1zm0-3h1v1h-1z`} />
        <path fill='#ef4354' d={`M0 ${waterfall}h1v1h-1z`} />
        <path fill='#f58220' d={`M0 ${waterfall + 4}h1v1h-1z`} />
        <path fill='#ef4354' d={`M0 ${waterfall + 3}h1v1h-1zm0 2h1v1h-1zm0 2h1v1h-1zm0-6h1v1h-1z`} />

        <path fill='#86c661' d={`M2 1${waterfall + 2}h1v1h-1zm0 1h1v1h-1z`} />
        <path fill='#04b3e9' d={`M2 1${waterfall + 4}h1v1h-1z`} />
        <path fill='#86c661' d={`M2 1${waterfall + 7}h1v1h-1zm0-1h1v1h-1z`} />
        <path fill='#04b3e9' d={`M2 1${waterfall + 9}h1v1h-1z`} />
        <path fill='#fbee41' d={`M1 1${waterfall}h1v1h-1zm0 2h1v1h-1z`} />
        <path fill='#f58220' d={`M1 1${waterfall + 1}h1v1h-1z`} />
        <path fill='#fbee41' d={`M1 1${waterfall + 4}h1v1h-1zm0 2h1v1h-1zm0-3h1v1h-1z`} />
        <path fill='#ef4354' d={`M0 1${waterfall}h1v1h-1z`} />
        <path fill='#f58220' d={`M0 1${waterfall + 4}h1v1h-1z`} />
        <path fill='#ef4354' d={`M0 1${waterfall + 3}h1v1h-1zm0 2h1v1h-1zm0 2h1v1h-1zm0-6h1v1h-1z`} />

        <path fill='#86c661' d={`M2 2${waterfall + 2}h1v1h-1zm0 1h1v1h-1z`} />
        <path fill='#04b3e9' d={`M2 2${waterfall + 4}h1v1h-1z`} />
        <path fill='#86c661' d={`M2 2${waterfall + 7}h1v1h-1zm0-1h1v1h-1z`} />
        <path fill='#04b3e9' d={`M2 2${waterfall + 9}h1v1h-1z`} />
        <path fill='#fbee41' d={`M1 2${waterfall}h1v1h-1zm0 2h1v1h-1z`} />
        <path fill='#f58220' d={`M1 2${waterfall + 1}h1v1h-1z`} />
        <path fill='#fbee41' d={`M1 2${waterfall + 4}h1v1h-1zm0 2h1v1h-1zm0-3h1v1h-1z`} />
        <path fill='#ef4354' d={`M0 2${waterfall}h1v1h-1z`} />
        <path fill='#f58220' d={`M0 2${waterfall + 4}h1v1h-1z`} />
        <path fill='#ef4354' d={`M0 2${waterfall + 3}h1v1h-1zm0 2h1v1h-1zm0 2h1v1h-1zm0-6h1v1h-1z`} />
      </svg>
    </div>
  )
}

export default Pond
