import { useEffect, useState } from 'react'
import farm from 'svg/farm.svg'
import * as Styled from './News.style'

const formatDate = (date) => {
  const d = new Date(date);
  const day = d.toLocaleDateString('en-GB', { day: 'numeric' });
  const month = d.toLocaleDateString('en-GB', { month: 'short' });
  const year = d.toLocaleDateString('en-GB', { year: 'numeric' });
  const time = d.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  return `${time} - ${day} ${month} ${year}`;
};

const News = ({ assets, socket }) => {
  const [news, setNews] = useState([])
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!init) {
      socket.emit('getNews')
      socket.on('news', data => setNews([...data].reverse()))
      setInit(true)
    }
  }, [init, socket])

  return (
    <Styled.Div>
      {news.map(({ headline, story, avatar, createdAt }, i) =>
        <div key={i}>
          <div>
            {assets.find(({ tokenId }) => tokenId === avatar) ? (
              <img src={assets.find(({ tokenId }) => tokenId === avatar).svg} alt={`#${avatar}`} />
            ) : (
              <img src={farm} alt={``} />
            )}
          </div>
          <div>
            <div>{headline}</div>
            <div>{story}</div>
            <div>{formatDate(createdAt)}</div>
          </div>
        </div>
      )}
    </Styled.Div>
  )
}

export default News
