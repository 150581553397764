import { useEffect, useState } from 'react'
import ghost from 'svg/horse/Ghost.svg'
import winningPost from 'svg/winning-post.svg'
import hay from 'svg/hay.svg'
import * as Styled from './Notifications.style'

const getImage = type => {
    if (type === 'Scare City') {
        return (
            <div style={{ 
                backgroundImage: `url(${ghost})`,
                backgroundSize: '300% auto',
                backgroundPosition: 'right 220%',
            }} />
        )
    }
    if (type === 'Horse Race' || type === 'Track Record') {
        return (
            <div style={{ 
                backgroundImage: `url(${winningPost})`,
                backgroundSize: '100% auto',
                backgroundPosition: 'center',
            }} />
        )
    }
    if (type === 'Hay distribution') {
        return (
            <div style={{ 
                backgroundImage: `url(${hay})`,
                backgroundSize: '100% auto',
                backgroundPosition: 'center',
            }} />
        )
    }
}

const Notifications = ({ account, socket }) => {
    const [notifications, setNotifications] = useState([]);
  
    useEffect(() => {
      const eventHandlers = {
        notification: (newNotification) => {
          (newNotification.account === account || newNotification.alertAll) &&
            setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
        },
      };
  
      Object.keys(eventHandlers).forEach((eventName) => {
        socket.on(eventName, eventHandlers[eventName]);
      });
  
      return () => {
        Object.keys(eventHandlers).forEach((eventName) => {
          socket.off(eventName, eventHandlers[eventName]);
        });
      };
    }, [socket]);
  
    useEffect(() => {
      if (notifications.length > 0) {
        const timer = setTimeout(() => {
          setNotifications((prevNotifications) => prevNotifications.slice(1));
        }, 8000); // Remove the first notification after 8 seconds
  
        return () => clearTimeout(timer);
      }
    }, [notifications]);
  
    return (
      <Styled.Container>
        {notifications.map((notification, index) => (
          <Styled.Div key={index} show={true}>
            <>
                {getImage(notification.type)}
                <div><b>{notification.type}:</b>{notification.message}</div>
            </>
          </Styled.Div>
        ))}
      </Styled.Container>
    );
  }
  
  export default Notifications;