import styled from 'styled-components'
import { gutters } from 'style/config'

export const Header = styled.header`
    position: fixed;
    z-index: 1000;
    right: 20px;
    top: 10px;
    > button {
        background-color: #EEE;
        border: 0;
        padding: ${gutters['sm']} ${gutters['md']};
        display: flex;
        align-items: center;
        border-radius: ${gutters['xs']};
        > svg {
            margin-right: ${gutters['sm']};
            width: 30px;
        }
    }
`