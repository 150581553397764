import styled from 'styled-components'

export const Div = styled.div`
  display : flex;
  position: absolute;
  flex-direction: column-reverse;
  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  z-index: 100;
  left: 10px;
  bottom: 10px;
  h1 {
    background-color: #FFF;
    display: flex;
    align-items: center;
    font-size: 2vw;
    padding: 1vw 4vw;
    border-radius: 4vw;
    border: 0.4vw solid #000;
    display: none;
    @media (min-width: 800px) {
      display: flex;
      font-size: 1.5vw;
      padding: 0.75vw 3vw;
      border-radius: 3vw;
      border: 0.15vw solid #000;
    }
    @media (min-width: 1000px) {
      font-size: 1.2vw;
      padding: 0.6vw 2.4vw;
      border-radius: 2.4vw;
      border: 0.12vw solid #000;
    }
  }
  > svg {
    width: 7.5vw;
    border-radius: 0.75vw;
    padding: 0.75vw;
    border: 0.4vw solid #000;
    margin-bottom: 1.2vw;
    @media (min-width: 800px) {
      width: 4vw;
      border-radius: 0.4vw;
      padding: 0.4vw;
      border: 0.2vw solid #000;
      margin-left: 1vw;
      margin-bottom: 0;
    }
    @media (min-width: 1000px) {
      width: 2.5vw;
      border-radius: 0.25vw;
      padding: 0.25vw;
      border: 0.15vw solid #000;
      margin-left: 0.6vw;
    }
    background-color: #FFF;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`
