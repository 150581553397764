import { useEffect, useState } from 'react'
import axios from 'axios'
import Mint from 'components/Mint'
import * as Styled from './SelectAvatar.style'

const SelectAvatar = ({ account, avatar, contract, horses, onSave }) => {
    const { REACT_APP_CS_ENDPOINT } = process.env

    const [avatarId, setAvatarId] = useState(avatar)
    const [loading, setLoading] = useState(false)
    const [bears, setBears] = useState(false)
    const [error, setError] = useState(false)

    // useEffect(() => {
    //     if (!bears) {
    //         axios
    //          .get(`${REACT_APP_CS_ENDPOINT}bears?wallet=${account}`)
    //          .then(({ data }) => setBears([...data]))
    //          .catch(e => setError(e))
    //     }
    // }, [])
    return (
        <Styled.Div>
            <div>
                <div><h2>Your Stable</h2></div>
            </div>
            <p>{'Account: '}<span>{account}</span></p>
            <p>{'Horses: '}{horses.length} {horses.length === 0 && '🤨 '}Bears: {bears.length}</p>
            {horses.length > 0 ? (
                <>
                    <ul>
                        {horses.map(({ tokenId, svg }) =>
                            <li key={tokenId}>
                                <img src={svg} alt={`Chained Horse #${tokenId}`} />
                                <span>#{tokenId}</span>
                            </li>
                        )}
                    </ul>
                    <p>{'Your main horse at the moment:'}</p>
                    <label>
                        <select defaultValue={avatar} onChange={e => setAvatarId(Number(e.target.value))}>
                            {avatarId < 0 && // No avatar set yet
                                <option value={-1}>{'Select horse'}</option>
                            }
                            {horses.map(({ tokenId }) =>
                                <option key={tokenId} value={tokenId}>
                                    {tokenId}
                                </option>
                            )}
                        </select>
                    </label>
                    <button 
                        disabled={!(avatarId >= 0 && avatarId !== avatar && !loading)}
                        onClick={() => {
                            setLoading(true)
                            onSave(avatarId)
                        }}
                    >{'Set as your main horse at the moment'}</button>
                </>
            ) : (
                <>
                    <h2>🐎 Mint a Chained Horse 🐎</h2>
                    <Mint {...{ account, contract }} />
                </>
               
            )}
        </Styled.Div>
    )
}

export default SelectAvatar
