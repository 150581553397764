import styled from 'styled-components'

export const Div = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: absolute;
  transform: scaleX(${({ lookleft }) => lookleft === 'true' ? -1 : 1 });
  z-index: 200;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  > div {
    width: 100%;
    text-align: center;
    margin-top: -10px;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.6;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    transform: scaleX(${({ lookleft }) => lookleft === 'true' ? -1 : 1 });
  }
`
