import styled from "styled-components";

export const Div = styled.div`
    h1 {
        line-height: 24px;
        font-size: 14px;
        @media (min-width: 800px) {
            font-size: 16px;
        }
    }

    p {
        line-height: 22px;
        font-size: 14px;
        @media (min-width: 800px) {
            font-size: 16px;
        }
        > a {
            text-decoration: underline dotted;
            :hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100%;
    overflow-y: scroll;
    > svg {
        width: 180px;
        margin: 48px 0 2.5vw;
        @media (min-width: 800px) {
            width: 250px;
            margin: 0 0 2.5vw;
        }
    }
    > h1 {
        margin: 0 5% 2.5vw;
        font-size: 2.5vw;
    }
    > h2 {
        margin: 0 5% 1vw;
        text-align: center;
        line-height: 2vw;
        font-size: 1.2vw;
    }
    > p {
        text-align: center;
        margin: 0 5% 64px;
        line-height: 1.6vw;
        font-size: 1vw;
    }
    > div {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 80%;
        margin: 0 5%;
        > div {
            width: 100%;
            margin-bottom: 64px;
            @media (min-width: 1000px) {
                width: 28%;
            }
            > img {
                width: 100%;
                border-radius: 10px;
                margin-bottom: 12px;
            }
            > p {
                font-size: 14px;
            }
        }
    }
`