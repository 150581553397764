import { useEffect, useRef, useState } from 'react'
import io from 'socket.io-client'
import Audio from 'components/Audio'
import Menu from 'components/Menu'
import Total from 'components/Total'
import Game from 'components/Game'
import Message from 'components/Message'
import Messages from 'components/Messages'
import News from 'components/News'
import AddNews from 'components/News/AddNews'
import HorseProfile from 'components/HorseProfile'
import Mint from 'components/Mint'
import SelectAvatar from 'components/SelectAvatar'
import * as Styled from './AppView.style'
import { bgColors } from 'style/config'

const AppView = ({ account, avatar, contract, onSaveAvatar }) => {
  const gameRef = useRef(null);
  const [total, setTotal] = useState(undefined)
  const [burnt, setBurnt] = useState(undefined)
  const [things, setThings] = useState(undefined);
  const [block, setBlock] = useState(undefined)
  const [horses, setHorses] = useState(undefined)
  const [filteredHorses, setFilteredHorses] = useState(undefined)
  const [attributes, setAttributes] = useState(undefined)
  const [socket, setSocket] = useState(false)
  const [players, setPlayers] = useState(undefined)
  const [showSection, setShowSection] = useState(-1)
  const [horseProfile, setHorseProfile] = useState(-1)
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    if (!socket && account) {
      const _socket = io(process.env.REACT_APP_END_POINT, { transports: ['websocket'] });
      const eventHandlers = {
        attributes: _attributes => {
          setAttributes(_attributes)
        },
        connect: () => {
          setSocket(_socket)
          if (!players) { _socket.emit('getPlayers') }
          if (!attributes) { _socket.emit('attributes') }
          if (!horses) { _socket.emit('horses') }
        },
        connect_error: (err) => {
          console.log(`connect_error due to ${err}`)
        },
        disconnect: () => {
          setPlayers(null)
          setSocket(null)
        },
        horses: _horses => {
          setHorses([..._horses])
        },
        move: _players => { setPlayers([..._players]) },
        things: _things => {
           setThings({ ..._things }) 
        },
        ethHeader: (block) => {
          setBlock(Number(block))
        },
      };
    
      Object.keys(eventHandlers).forEach((eventName) => {
        _socket.on(eventName, eventHandlers[eventName]);
      });
    }
  }, [socket, account]);

  // useEffect(() => {
  //   console.log(horses)
  // }, [horses])

  useEffect(() => {
    if (total === undefined) {
      getTotalSupply(contract).then(_total =>
        getBurnt(contract).then(_burnt => {
          setTotal(_total)
          setBurnt(_burnt)
        })
      )
  }},[])

  useEffect(() => {
    if (searchString.length > 2 && attributes && horses) {
      const attributeNamesToSearch = [
        'background',
        'bodyAccessory',
        'bodyColor',
        'headAccessory',
        'hoofColor',
        'mane',
        'maneColor',
        'pattern',
        'patternColor',
        'tail',
        'utility'
      ];
  
      const _filteredHorses = horses.filter(horse => {
        return attributeNamesToSearch.some(attributeName => {
          const attributeValue = horse[attributeName];
          if (typeof attributeValue === 'string') {
            return attributeValue.toLowerCase().includes(searchString.toLowerCase());
          }
          return false; // Exclude non-string attributes
        });
      });
      setFilteredHorses(_filteredHorses)
    } else {
      setFilteredHorses(undefined)
    }
  }, [searchString, attributes, horses]);

  const getTotalSupply = async contract => await contract.methods.totalSupply().call()
  const getBurnt = async contract => await contract.methods.burnedTokens().call()

  const handleClickMenu = itemId => {
    return itemId === showSection ? (
      setShowSection(-1)
  ) : (
      setShowSection(itemId)
  )}

  const handleHorseClick = horse => {
    setHorseProfile(horse.tokenId)
    setShowSection(7)
  }

  const closeModal = () => {
    setShowSection(-1)
    setHorseProfile(-1)
    gameRef.current.container.current.focus()
  }
    

  return (
    <Styled.Main style={{ backgroundColor: bgColors['starlight']}}>
      <Menu {...{ account, avatar }} handleClick={handleClickMenu} />
      <Audio userClicked={() => gameRef.current && gameRef.current.container.current.focus()} />
      {horses && attributes && (
        <>
          {avatar < 0 && (
            <SelectAvatar
              {...{ account, avatar, contract }}
              horses={horses.filter(({ owner }) => owner.toLowerCase() === account.toLowerCase())}
              onSave={onSaveAvatar}
            />
          )}
          {showSection >= 0 && avatar >= 0 && (
              <Styled.Div>
                <div>
                  <p onClick={closeModal}>{'X'}</p>
                  {showSection === 0 && (
                    <SelectAvatar
                      {...{ account, avatar, contract }}
                      horses={horses.filter(({ owner }) => owner.toLowerCase() === account.toLowerCase())}
                      onSave={tokenId => onSaveAvatar(tokenId).then(() => {
                        setShowSection(-1)
                        window.location.reload(false); // TODO: bit skanky but works for now
                      })}
                    />
                  )}
                  {showSection === 1 && (
                    <Styled.Section>
                      <h2>🐎 Engagement Farm 🐎</h2>
                      <Message {...{ account, socket }} />
                      <Messages assets={horses} accounts={players} {...{ socket }} />
                    </Styled.Section>
                  )}
                  {showSection === 2 && (
                    <Styled.Section>
                      <h2>🐎 Chained Horses 🐎</h2>
                      <h3><a href={'/mint'} onClick={e => {
                        e.preventDefault()
                        setShowSection(6)
                      }}>+ Mint a Chained Horse</a></h3>
                      <input type={'text'} value={searchString} onChange={e => setSearchString(e.target.value)} />
                      <ul>
                        {!filteredHorses ? horses.map(({ tokenId, svg }) =>
                          <li key={tokenId}>
                            <img src={svg} alt={`Chained Horse #${tokenId}`} />
                            <b>{`#${tokenId}`}</b>
                          </li>
                        ) : filteredHorses.map(({ tokenId, svg }) =>
                        <li key={tokenId}>
                          <img src={svg} alt={`Chained Horse #${tokenId}`} />
                          <b>{`#${tokenId}`}</b>
                        </li>
                      )}
                      </ul>
                    </Styled.Section>
                  )}
                  {showSection === 3 && (
                    <Styled.Section>
                      <h2>Audioglyph #160:</h2>
                      <iframe width="640" height="640" title={'Audioglyph #160'} src="https://audioglyphs.io/embed?id=160&v=y"></iframe>
                    </Styled.Section>
                  )}
                  {showSection === 4 && (
                    <Styled.Section>
                      <h2>WFT...</h2>
                      <div>
                          <h3>Music</h3>
                          <p>"Sumer Is Icumen"<br /><a to={{ pathname: 'https://www.youtube.com/watch?v=nJKhWhmmHEU'}} target="_blank" rel="noopener noreferrer">First Light Ensemble</a></p>
                          <h3>Paddock Tech</h3>
                          <p><a to={{ pathname: 'https://cryptosystems.online'}} target="_blank" rel="noopener noreferrer">{'cryptosystems.online'}</a></p>
                          <h3>Chained Horse Tech</h3>
                          <p><a to={{ pathname: 'https://twitter.com/moonfarm_eth'}} target="_blank" rel="noopener noreferrer">{'Moonfarm.eth'}</a></p>
                      </div>
                    </Styled.Section>
                  )}
                 {showSection === 5 && (
                    <Styled.Section>
                       <h2>🐎 Paddock News 🐎</h2>
                       <News assets={horses} {...{ socket }} />
                       {/* <AddNews title={'🐎 Add News 🐎'} {...{ account, socket }} /> */}
                    </Styled.Section>
                  )}
                  {showSection === 6 && (
                    <Styled.Section>
                      <h2>🐎 Mint a Chained Horse 🐎</h2>
                      <Mint {...{ account, contract }} />
                    </Styled.Section>
                  )}
                  {showSection === 7 && (
                    <Styled.Section style={{ backgroundColor: bgColors[horses.find(({ tokenId }) => tokenId === horseProfile).background]}}>
                      <HorseProfile horse={horses.find(({ tokenId }) => tokenId === horseProfile)} {...{ attributes, total }} />
                    </Styled.Section>
                  )}
                </div>
              </Styled.Div>
          )}
          {avatar >= 0 && socket && (
              <Game 
                {...{ account, attributes, block, horses, things, players, total, socket, gameRef, handleHorseClick }}
                murdered={horses.filter(horse => horse.owner === "0x0000000000000000000000000000000000000000")}
                avatarId={avatar}
              />
          )}
          {/* {total > 0 && <Total {...{ total, burnt }}/>} */}
        </>
      )}
    </Styled.Main>
  )
}

export default AppView


