import styled from "styled-components";

export const Div = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 96px 24px;
    overflow: auto;
    > h2 {
        margin: 24px 0;
        font-weight: bold;
        font-size: 10px;
        @media (min-width: 600px) {
            font-size: 14px;
        }
        @media (min-width: 600px) {
            font-size: 16px;
        }
    }
    > p {
        text-align: center;
        > span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
            @media (min-width: 600px) {
                max-width: 800px;
            }
            
        }
    }
    > div {
        width: 100%;
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            > h2 {
                margin-bottom: 24px;
                font-weight: bold;
                font-size: 18px;
                @media (min-width: 600px) {
                    font-size: 24px;
                }
                
            }
        }

    }
   
    > p, select {
        margin-bottom: 16px;
    }
    > ul {
        display: flex;
        width: 80%;
        margin: 36px 0;
        justify-content: space-between;
        flex-wrap: wrap;
        > li {
            width: 100%;
            @media (min-width: 500px) {
                width: 46%;
            }
            @media (min-width: 800px) {
                width: 30%;
            }
            @media (min-width: 1000px) {
                width: 22%;
            }
            margin-bottom: 20px;
            > img {
                width: 100%;
                border-radius: 4px;
            }
            > span {
                display: block;
                font-weight: bold;
            }
        }
        
    }
    label {
        position: relative;
        display: inline-block;
        margin-bottom: 12px;
        &:before {
            content: '';
            position: absolute;
            right: 7px;
            top: 3px;
            width: 22px;
            background: -webkit-linear-gradient(#fff, #f0f0f0);
            background: -moz-linear-gradient(#fff, #f0f0f0);
            background: linear-gradient(#f5f5f5, #e0e0e0);
            background: #fff; //for Firefox in Android
        
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            pointer-events: none;
            display: block;
        }
        &:after {
            content: " ";
            position: absolute;
            right: 15px;
            top: 46%;
            margin-top: -3px;
            z-index: 2;
            pointer-events: none;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6.9px 4px 0 4px;
            border-color: #aaa transparent transparent transparent;
            pointer-events: none;
        }
        select {
            appearance: none;
            padding: 8px 30px 8px 10px;
        
            border: 1px solid #e0e0e0;
            border-radius: 3px;
            line-height: $height + px;
            height: $height + px;
            //box-shadow: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
            background: #fff;
        
            //min-width: 200px;
            margin: 0 5px 5px 0;
        }
    }
    //fix for ie 10 later
    select::-ms-expand {
        display: none;
    }

    > button {
        background: #FFF;
        padding: 8px 12px;
    }
   
`