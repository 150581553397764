import bonfire from 'svg/horse/Bonfire.svg'
import * as Styled from './Cemetry.style'

const Cemetry = ({ murdered, left, top, width, position, zIndex }) => {
    return (
        <Styled.Div style={{ left, top, width, position, zIndex }}>
            <div>
                <h2>{'The Cemetry'}<span>{'please don\'t murder horses...'}</span></h2>
                <div>
                    <div style={{ backgroundImage: `url(${bonfire})`}} />
                    <div style={{ backgroundImage: `url(${bonfire})`}} />
                    <div style={{ backgroundImage: `url(${bonfire})`}} />
                    <div style={{ backgroundImage: `url(${bonfire})`}} />
                </div>
                <div>
                    {murdered.map((horse, i) => {
                        
                        return (
                            <div key={i}>
                                <div style={{ backgroundImage: `url(${horse.svg})`}} />
                                <p>#{horse.tokenId}</p>
                            </div>
                            
                        )
                    })}
                </div>
                <div>
                    <div style={{ backgroundImage: `url(${bonfire})`}} />
                    <div style={{ backgroundImage: `url(${bonfire})`}} />
                    <div style={{ backgroundImage: `url(${bonfire})`}} />
                    <div style={{ backgroundImage: `url(${bonfire})`}} />
                </div>
            </div>
            <div />
        </Styled.Div>
    )
}

export default Cemetry