import { useEffect, useState } from 'react'
import * as Styled from './LeaderBoard.style'

const LeaderBoard = ({ left, top, width, height, socket, race }) => {
    const [record, setRecord] = useState(undefined)
    useEffect(() => {
        if (!record) {
            socket.emit('raceRecords')
        }
        socket.on('raceRecords', raceRecords => {
            if (raceRecords.find(_race => _race.race === race)) {
                const _record = raceRecords.find(_race => _race.race === race)
                setRecord(_record)
            }
        })
    },[socket])

    return (
        <Styled.Div style={{ left, top, width, height }}>
            <h3>🐎 {race} 🐎</h3>
            <div>
                Track Record: {record ? (
                <>#{record.tokenId} - {record.time / 1000}s<br />rider: {record.winner}</>
            ) : (
                <>...<br />rider: ...</>
            )}
            </div>
        </Styled.Div>
    )
}

export default LeaderBoard