import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    bottom: 52px;
    right: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
`;

export const Div = styled.div`
    background-color: #FFF;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    opacity: ${(props) => (props.show ? '1' : '0')};
    transition: opacity 0.5s ease-in-out;
    font-size: 12px;
    z-index: 1000000000000000;
    width: 340px;
    display: flex;
    align-items: center;
    margin-top: 12px;
    line-height: 15px;
    > div {
        &:first-of-type {
            flex: 0 0 50px;
            height: 50px;
            margin-right: 8px;
        }
        &:last-of-type {
            > b {
                display: block;
            }
        }
    }
`