import * as Styled from './Horse.style'
import { decode, encode } from 'js-base64'

const Horse = ({ horse, horse: { tokenId, svg: imgsrc }, left, top, transform, size, textTop, handleHorseClick }) => {
  const svg = decode(imgsrc.split(',')[1])
  const param = imgsrc.split(',')[0]
  const _svg = []
  svg.split('><').map((bit, i) => bit.indexOf(`rect width='32' height='32' fill='#`) !== -1 ?
    _svg.push(`<rect width='32' height='32' fill='transparent' />`)
    : i === 0 ? _svg.push(`${bit}>`)
      : i === svg.split('><').length - 1 ? _svg.push(`<${bit}`)
        : _svg.push(`<${bit}>`) // Manky.. strips out the background
  )
  return (
    <Styled.Div lookleft={transform ? 'true' : 'false'} style={{ left, top }} {...{ size }} onClick={() => handleHorseClick(horse)}>
      <img src={param + ',' + encode(_svg.join(''))} />
      <div style={textTop ? ({ position: 'absolute', top: 0 }) : undefined}>#{tokenId}</div>
    </Styled.Div>
  )
}

export default Horse
