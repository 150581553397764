import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import * as Styled from './Audio.style';

const Audio = ({ userClicked }) => {
  const musicUrl = '/Sumer.mp3';
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(false);

  const toggleMute = () => {
    setMuted(!muted);
    userClicked()
  };

  const startAudio = () => {
    setPlayed(true);
    userClicked()
  };

  return (
    <Styled.Div>
      {!played ? (
        <button onClick={startAudio}>🎶 Start Audio</button>
      ) : (
        <>
          <ReactPlayer
            url={musicUrl}
            playing={true}
            muted={muted}
            loop={false}
            volume={0.2}
            width={0}
            height={0}
          />
          <button onClick={toggleMute}>{muted ? '🎶 Unmute' : '🎶 Mute'}</button>
        </>
      )}
    </Styled.Div>
  );
}

export default Audio;




