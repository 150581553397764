import styled from 'styled-components'

export const Div = styled.div`
    position: fixed;
    z-index: 99;
    top: 14px;
    left: 12px;
    > button {
        background-color: #FFF;
        border: 0;
        padding: 4px 6px;
        border-radius: 4px;
    }
`