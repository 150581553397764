import { useEffect, useState } from 'react'
import * as Styled from './HorseProfile.style'
const traitTypes = [
    'background', 'bodyAccessory','bodyColor','headAccessory','hoofColor',
    'mane','maneColor','pattern','patternColor','tail','utility'
]
const bgColors = {
    grape: '#ffdfff',
    fog: '#dadee9',
    seafoam: '#aaffcf',
    starlight: '#dfefff',
    ice: '#bbe4ea',
    'ghost green': '#efefcf',
    chestnut: '#ddadaf',
    sand: '#ffefcf',
    banana: '#ffefbf',
    curd: '#fff9d0'
  }

const HorseProfile = ({ horse, attributes, total }) => {
    const [rarity, setRarity] = useState(undefined)
    const getAttributeRarity = (attributeType, attributeName) =>
        parseFloat((100 / total) * attributes.find(({ name, value }) => name === attributeType && value === attributeName).amount).toFixed(2)

    useEffect(() => {
        if (!rarity) {
            let r = 0
            traitTypes.map((traitType, i) => {
                r = r + parseFloat(getAttributeRarity(traitType, horse[traitType]))
                if (i === traitTypes.length - 1) {
                    setRarity((r / traitTypes.length).toFixed(2))
                }
            })
        }
        
    }, [horse])

    return (
        <Styled.Div>
            <div>
                <div>
                    <img src={horse.svg} alt={`Chained Horse ${horse.tokenId}`} />
                </div>
                <div>
                    <ul>
                        {traitTypes.map((traitType, i) => 
                            <li key={i}>{traitType}: <span>{horse[traitType]}</span> <span>{getAttributeRarity(traitType, horse[traitType])}%</span></li>    
                        )}
                    </ul>
                </div>
            </div>
            
            <div>
                <p>{`Chained Horse #${horse.tokenId}`}<span style={{ border: `1px solid ${bgColors[horse.background]}`}}>overall rarity: <span>{rarity}</span></span></p>    
                <p>Owner: {horse.owner}</p>
                <p>Minted by: {horse.owners[0]}</p>
                <p>Birth block: {horse.blockNumber}</p>
                <p>Birth timestamp: {1636117146} {}</p>
                <p>Log Index: {horse.logIndex}</p>
            </div>
        </Styled.Div>
    )
}

export default HorseProfile