import styled from "styled-components";

export const Div = styled.div`
    margin-bottom: 24px;
    overflow: auto;
    > div {
        margin-bottom: 24px;
        display: flex;
        > div {
            &:first-of-type {
                margin-right: 12px;
                > div {
                    border-radius: 100%;
                    width: 35px;
                    height: 35px;
                    margin: 0 8px;
                    @media (min-width: 600px) {
                        width: 64px;
                        height: 64px;
                        margin: 0 15px;
                    }
                }
            }
            &:last-of-type {
                flex-grow: 1;
                > div {
                    background-color: #EEE;
                    padding: 6px 12px;
                    &:first-of-type {
                        background-color: #CDCDCD;
                        overflow: hidden;
                        > span {
                            display: inline-block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 220px;
                            @media (min-width: 600px) {
                                width: auto;
                            }
                        }
                    }
                    &:last-of-type {
                        background-color: #FFF;
                        font-size: 12px;
                    }
                }
            }
        }
    }
`