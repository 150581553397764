import styled from 'styled-components'
import church from 'svg/church.svg'

export const Div = styled.div`
    display: flex;
    > div {
        &:first-of-type {
            display: flex;
            flex: 1;
            margin-right: 5%;
            flex-direction: column;
            background-color: rgba(0,0,0,0.1);
            border-radius: 3%;
            > h2 {
                position: absolute;
                margin-top: -30px;
                font-size: 12px;
                opacity: 0.5;
                > span {
                    font-weight: normal;
                    font-size: 11px;
                    margin-left: 50px;
                }
            }
            > div {
                display: flex;
                &:first-of-type, &:last-of-type {
                    width: 100%;
                    justify-content: space-between;
                    > div {
                        width: 18%;
                        aspect-ratio: 1 / 1;
                        background-size: 300% auto;
                        background-position: right 72%;
                    }
                }
                &:first-of-type {
                    margin-right: 2%;
                }
                &:last-of-type {
                    margin-left: 2%;
                    margin-bottom: 50px;
                }
                &:nth-of-type(2) {
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 10% 9% 2% 12%;
                    > div {
                        width: 18%;
                        margin-bottom: 5%;
                        > div {
                            width: 100%;
                            aspect-ratio: 1 / 1;
                            background-repeat: no-repeat;
                            margin-bottom: 5%;
                        }
                        > p {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        &:last-of-type {
            width: 160px;
            aspect-ratio: 1 / 1;
            background-image: url(${church});
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    
`