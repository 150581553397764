import styled from "styled-components";

export const Div = styled.div`
    > h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;
    }
    padding: 100px 5%;
    display: flex;
    flex-direction: column;
    > input, textarea {
        margin-bottom: 24px;
        padding: 12px 8px;
    }
`