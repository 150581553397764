import { useEffect, useState } from 'react'
import * as Styled from './Clock.style'

const Clock = ({ socket, left, top, width, height }) => {
  const [time, setTime] = useState(null)

  useEffect(() => {
    // Define a function to handle the 'ethHeader' event
    const handleEthHeaderEvent = (data) => setTime(data)
    socket.on('ethHeader', handleEthHeaderEvent)
    return () => {
      socket.off('ethHeader', handleEthHeaderEvent)
    };
  }, [socket])

  return (
    <Styled.Div id={'clock'} style={{ left, top, width, height }}>
        <h2>⏳</h2>
        <p>{time && time}<br />block o'clock</p>
    </Styled.Div>
  )
}

export default Clock;
