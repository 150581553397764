import styled from 'styled-components'

export const Div = styled.div`
    position: fixed;
    right: 10px;
    bottom: 10px;
    zIndex: 1000;
    background-color: #FFF;
    font-size: 2.5vw;
    border-radius: 0.5vw;
    padding: 1vw 0.9vw;
    border: 0.25vw solid #CCC;
    @media (min-width: 800px) {
        font-size: 2vw;
        border-radius: 0.4vw;
        padding: 0.8vw 0.75vw;
        border: 0.2vw solid #CCC;
    }
    @media (min-width: 1000px) {
        font-size: 1.25vw;
        border-radius: 0.3vw;
        padding: 0.6vw 0.75vw;
        border: 0.2vw solid #CCC;
    }
`