import { useEffect, useState } from 'react'
import Intro from 'components/Intro'
import HayBalance from 'components/HayBalance'
import Notifications from 'components/Notifications'
import MobileNavButton from 'components/MobileNavButton'

function Game({
  account,
  attributes,
  block,
  horses,
  murdered,
  players,
  things,
  total,
  socket,
  gameRef,
  avatarId, // TODO - look into this.
  handleHorseClick,
}) {
  const size = useWindowSize();
  const paddockWidth = 10000;
  const paddockHeight = 10000;
  const [gameSectionTransition, setGameSectionTransition] = useState(false);
  return (
    <>
      {players && things && size.width && avatarId && 
       players.find(player => player.account === account) && 
       players.find(player => player.account === account).level === 0 && (
        <>
          <Intro
            {...{
              account,
              attributes,
              avatarId,
              block,
              horses,
              handleHorseClick,
              murdered,
              players,
              things,
              total,
              size,
              paddockWidth,
              paddockHeight,
              socket,
              gameRef,
            }}
            level={0}
            changeSection={() => setGameSectionTransition(true)}
          />
          <Notifications {...{ account, socket }} />
          <HayBalance balance={players.find(player => player.account === account).hay} />
          <MobileNavButton
            _key={'ArrowRight'}
            keyCode={39}
            style={{ right: 10, top: '50vh' }}
            __html={'⬅'}
            {...{ gameRef }}
            flip={'true'}
          />
          <MobileNavButton
            _key={'ArrowLeft'}
            keyCode={37}
            style={{ left: 10, top: '50vh' }}
            __html={'⬅'}
            {...{ gameRef }}
          />
          <MobileNavButton
            _key={'ArrowUp'}
            keyCode={38}
            style={{ left: '50vw', top: 10 }}
            __html={'⬆'}
            {...{ gameRef }}
          />
          <MobileNavButton
            _key={'ArrowDown'}
            keyCode={40}
            style={{ left: '50vw', bottom: 10 }}
            __html={'⬇'}
            {...{ gameRef }}
          />
        </>
      )}
    </>
  );
}

export default Game;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}