import { useEffect, useState } from 'react'
import { fromWei } from 'web3-utils'
import BN from 'bignumber.js'
import * as Styled from './Mint.style'

const Mint = ({ account, contract }) => {
    const [price, setPrice] = useState(undefined)
    const [minting, setMinting] = useState(false)
    const [mintPrice, setMintPrice] = useState(undefined)
    
    useEffect(() => {
        const getMintPrice = async () => {
            const _price = await contract.methods.mintPrice().call()
            setPrice(_price)
        }
        if (price === undefined) {
            getMintPrice()
        }
        
    }, [price])

    return (
        <Styled.Div>
            {price &&
                <>
                    <p>Mint price: <b>{`Ξ${fromWei(price, 'ether')}`}</b></p>
                    {[1,2,3,4,5].map((mintAmount, i) => {
                        const value = new BN(price).times(mintAmount)
                        return (
                            <button disabled={minting} key={i} onClick={async () => {
                                try {
                                    setMinting(true)
                                    const tx = contract.methods.publicMint(mintAmount)
                                    tx.send({ from: account, value: value.toString() }).then(res => {
                                        setMinting(false)
                                    }).catch(e => {
                                        setMinting(false)
                                    })
                                } catch (e) { setMinting(false) }
                                }}>{`Mint ${mintAmount === 1 ? `a Chained Horse` : `${mintAmount} Chained Horses`}`}</button>
                        )
                    })}
                </>
            }
            
        </Styled.Div>
    )
}

export default Mint