import styled from 'styled-components'

export const Div = styled.div`
  position: absolute;
  width: ${({ width }) => `${width}`};
  height: ${({ width }) => `${width}`};
  background-image: url('${({ imgsrc }) => imgsrc}');
  background-size: 300% auto;
  background-position: right 220%;
  z-index: 200;
  > div {
    position: absolute;
    display: none;
    bottom: -40px;
    z-index: 100000;
  }
  &:hover {
    > div {
      position: absolute;
      display: block;
      line-height: 11px;
      font-size: 9px;
      background-color: #FFF;
      padding: 4px 6px;
      border-radius: 4px;
    }
  }
`
