import styled from "styled-components";

export const Div = styled.div`
    margin-bottom: 24px;
    overflow: auto;
    flex-grow: 1;
    > div {
        margin-bottom: 24px;
        display: flex;
        > div {
            &:first-of-type {
                width: 100px;
                margin-right: 12px;
                > img {
                    width: 70px;
                    border-radius: 100%;
                    margin: 0 15px;
                }
            }
            &:last-of-type {
                flex-grow: 1;
                > div {
                    background-color: #EEE;
                    padding: 6px 12px;
                    &:first-of-type {
                        background-color: #CDCDCD;
                    }
                    &:last-of-type {
                        background-color: #FFF;
                        font-size: 12px;
                    }
                }
            }
        }
    }
`