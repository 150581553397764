import styled from 'styled-components'

export const Div = styled.div`
    position: absolute;
    z-index: 2;
    background-color: #FFF;
    border-radius: 6px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    > h3 {
        margin-bottom: 12px;
        font-weight: bold;
    }
    > div {
        width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;
        > span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`