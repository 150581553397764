import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import Header from '../components/Header'
import SignInC2A from 'components/SignInC2A'
import Paddock from './Paddock'
import Core from 'contracts'
import Web3 from 'web3'
// import * as Styled from 'style'

const AppView = ({ handleSignIn, handleSignOut, loggedIn, onSaveAvatar, avatar }) => {
    const { REACT_APP_END_POINT } = process.env
    const [block, setBlock] = useState(undefined)
    const [contract, setContract] = useState(undefined)
    useEffect(() => {
      if (loggedIn && !contract) {
        const web3 = new Web3(window.ethereum)
        const instance = new web3.eth.Contract(Core.abi, Core.addr)
        setContract(instance)
      }
    }, [loggedIn, contract])

    return (
        <Router>
            <ScrollToTop />
            <Header {...{ handleSignIn, handleSignOut, loggedIn }} />
            {loggedIn && contract ? (
              <Paddock account={loggedIn} {...{ avatar, contract, onSaveAvatar }} />
            ) : (
              <SignInC2A handleSignMessage={handleSignIn} />
            )}
        </Router>
    )
    
}

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' })}, [pathname])
  return null
}

export default AppView