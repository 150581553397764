import * as Styled from './SignInC2A.style'
import { ReactComponent as Horse21 } from'svg/horse/21.svg'
import promo1 from 'img/promo1.jpg'
import promo2 from 'img/promo2.jpg'
import promo3 from 'img/promo3.jpg'

const SignInC2A = ({ handleSignMessage }) => {
    return (
        <Styled.Div>
            <Horse21 />
            <h1>The Paddock</h1>
            <h2>{'Welcome to the Meadowverse...'}</h2>
            <p>🐎 Please <a to={''} onClick={e => {
                e.preventDefault()
                handleSignMessage()
            }}>sign in with Metamask</a> to visit The Paddock 🐎</p>
            <div>
                <div>
                    <img src={promo2} alt={'Touching grass'} />
                    <p>{'Touch some grass and enjoy some solitude...'}</p>
                </div>
                <div>
                    <img src={promo1} alt={'Engagement Farm'} />
                    <p>{'Hang out with other horse owners at Engagement Farm...'}</p>
                </div>
                <div>
                    <img src={promo3} alt={'Horse Racing'} />
                    <p>{'Or take part in an enjoyable race...'}</p>
                </div>
            </div>
        </Styled.Div>
    )
}

export default SignInC2A