import * as Styled from './Header.style'
import { ReactComponent as Metamask } from './metamask.svg'

const Header = ({ loggedIn, handleSignIn, handleSignOut }) => {
    return (
        <Styled.Header>
            {loggedIn
                ? <button onClick={handleSignOut}><Metamask />{'Sign out'}</button>
                : <button onClick={handleSignIn}><Metamask />{'Sign in'}</button>
            }
        </Styled.Header>
    )
    
}

export default Header